<template>
  <div>
    <div>
      <div class="">
        <ValidationObserver ref="formCreateReseller">
          <form>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="reseller_type" :options="resellerTypes" nameSelector="name" optionSelector="id" field_name="Typ" />
              </div>
              <div v-if="reseller_type == 'alarm_center'" class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="alarm_center" :options="alarmCenters" nameSelector="name" optionSelector="_id" field_name="Larmcentral" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="name" type="text" field_name="Namn" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="company_number" type="text" field_name="Org. Nummer" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="email" type="text" field_name="Email" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="phone" type="text" field_name="Telefon" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="address" type="text" field_name="Adress" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="zip_code" type="text" field_name="Postnummer" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="city" type="text" field_name="Stad/Ort" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="reference" type="text" field_name="Ref." rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="invoice" type="text" field_name="E-Faktura" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="contact_person" type="text" field_name="Kontaktperson" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="contact_email" type="text" field_name="Kontakt Email " rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="contact_phone" type="text" field_name="Kontakt Telefon " rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-3/3 px-2 py-2">
                <BaseTextarea rows="5" v-model="description" field_name="Description" rules="required" />
              </div>
            </div>
             <!-- White-label options -->
             <div v-if="user.role == 'admin'" class="mx-2 border-b border-secondary-400 my-5"></div>
            <div v-if="user.role == 'admin'" class="flex flex-wrap">
              <div v-if="user.role == 'admin'" class="w-full px-2 py-2">
                  <p>
                    Om white-label är vald kommer logotyp synas i vänstra menyn istället för Securdia.
                    <br>
                    Detta berör användarkonton "Verksamhet" och "Kund" som tillhör den här återförsäljaren
                  </p>
              </div>
            </div>
            <div v-if="user.role == 'admin'" class="flex flex-wrap px-2 py-2">
              <div v-if="user.role == 'admin'" class="w-1/3 md:w-1/3 px-2 py-2">
                <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Logo<b class="text-red-500 ml-1">*</b></label>
                <input type="file" accept="image/*" ref="imageCreate" @change="fileChangeCreate()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-1" />
              </div>
              <div v-if="user.role == 'admin'" class="w-1/3 md:w-1/3 px-2 py-2">
                <ValidationProvider name="White-label" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <label class="text-gray-700 text-sm font-bold pb-2 font-serif">{{ $t("reseller_edit_modal.white-label") }}<b class="text-red-500 ml-1">*</b></label>
                    <select v-model="whitelabel" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400">
                      <option :value="o['value']" v-for="(o, i) in whitelabeloptions" :key="i">{{ o["name"] }}</option>
                    </select>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="px-2 py-2">
                <p>Alternativa adresser för felanmälan och beställningar. Ska dessa gå till Securdia lämnas fälten blanka</p>
              </div>
            <div v-if="user.role == 'admin' && whitelabel" class="" >
              <div class="flex flex-wrap">
                <div class="w-full md:w-1/3 px-2 py-2">
                  <BaseInput v-model="alternativ_order_email" type="text" field_name="Adress för ordrar" rules="email"/>
                </div>
                <div class="w-full md:w-1/3 px-2 py-2">
                  <BaseInput v-model="alternativ_error_email" type="text" field_name="Adress för felanmälan" rules="email"/>
                </div>
              </div>
            </div>
            <!-- End of White-label options-->

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2" v-for="o in pricelist" :key="o.id">
                <div class="w-full text-md font-bold mb-4">
                  <label class="inline-flex items-center mt-3">
                    <input v-model="o.active" type="checkbox" class="form-checkbox h-4 w-4" />
                    <span class="ml-2 text-gray-600 text-md font-serif font-bold" v-if="o.id == 'cash'">Kontant</span>
                    <span class="ml-2 text-gray-600 text-md font-serif font-bold" v-else-if="o.id == 'rental'">Hyres</span>
                    <span class="ml-2 text-gray-600 text-md font-serif font-bold" v-else-if="o.id == 'kickback'">Kickback</span>
                  </label>
                </div>
                <div v-if="o.active">
                  <div class="w-full text-md font-sans font-semibold mt-5 mb-6">
                    <span v-if="o.id == 'cash'">Kontant</span>
                    <span v-else-if="o.id == 'rental'">Hyres</span>
                    <span v-else-if="o.id == 'kickback'">Kickback</span>
                    Prislista
                  </div>
                  <div class="flex flex-wrap my-2" v-for="p in o.prices" :key="p.id">
                    <div class="w-2/6 text-sm" v-bind:class="{ 'opacity-40': p.price == 0 }">
                      {{ p.name }}
                    </div>
                    <div class="w-2/6" v-bind:class="{ 'opacity-40': p.price == 0 }">
                      <ValidationProvider :name="p.name" rules="required" v-slot="{ classes, errors }">
                        <div class="input-validate" :class="classes">
                          <input type="number" v-model="p.price" min="0" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="w-1/6" v-bind:class="{ 'opacity-40': p.price == 0 }">
                      <span class="pl-2 text-xs" v-if="p.price_type == 'month'"> / månad</span>
                    </div>
                    <div class="w-1/6" v-bind:class="{ 'opacity-40': p.price == 0 }">
                      <span @click.prevent="setPriceZero(o.id, p.id)" class="cursor-pointer text-xs"><BaseIcon icon="times-circle" class="text-red-400"/></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full font-bold text-md px-2 py-2">Särskild tillgång</div>
              <template v-for="s in specialAccess">
                <div class="w-full md:w-1/3 px-2 py-2" v-bind:key="s.id">
                  <label class="inline-flex items-center mt-3">
                    <input v-model="special_access" :value="s.id" type="checkbox" class="form-checkbox h-4 w-4" />
                    <span class="ml-2 text-gray-600 text-sm font-serif font-bold">{{ s.name }}</span>
                  </label>
                </div>
              </template>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full px-2">
                <div class="clearfix">
                  <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createReseller()">
                    Spara
                    <BaseIcon icon="arrow-right" class="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return `Ny Återförsäljare | SecurCloud`;
  },
  data() {
    return {
      user: this.$store.state.user,
      alarmCenters: [],
      reseller_type: "",
      alarm_center: "",
      name: "",
      email: "",
      phone: "",
      description: "",
      logo: null,
      company_number: "",
      address: "",
      zip_code: "",
      city: "",
      reference: "",
      invoice: "",
      contact_person: "",
      contact_phone: "",
      contact_email: "",
      special_access: [],
      resellerTypes: [{ id: "alarm_center", name: "Larmcentraler" }],
      whitelabel: false,
      alternativ_order_email: "",
      alternativ_error_email: "",
      pricelist: [
        {
          id: "cash",
          active: true,
          prices: [
            { id: "SRT306", name: "SRT306", price: "3450", price_type: "total" },
            { id: "SRT306i", name: "SRT306i", price: "4625", price_type: "total" },
            { id: "SRT306-G2", name: "SRT306 G2", price: "3850", price_type: "total" },
            { id: "SRT326", name: "SRT326", price: "2780", price_type: "total" },
            { id: "SRT330", name: "SRT330", price: "5900", price_type: "total" },
            { id: "SRT334", name: "SRT334", price: "1670", price_type: "total" },
            { id: "SRT405", name: "SRT405", price: "4373", price_type: "total" },
            { id: "SRT405i", name: "SRT405i", price: "0", price_type: "total" },
            { id: "SRT406", name: "SRT406", price: "4601", price_type: "total" },
            { id: "SRT406i", name: "SRT406i", price: "5191", price_type: "total" },
            { id: "SRT430", name: "SRT430", price: "0", price_type: "total" },
            { id: "SRT278", name: "SRT278", price: "0", price_type: "total" },
            { id: "SRT341", name: "SRT341", price: "0", price_type: "total" },
            { id: "TWIG-Neo", name: "TWIG Neo", price: "2500", price_type: "total" },
            { id: "TWIG-IPS", name: "TWIG Neo SRD", price: "4625", price_type: "total" },
            { id: "TWIG-Neo-Mandown", name: "TWIG Neo MD", price: "3175", price_type: "total" },
            { id: "TWIG-Neo-SRD-MD", name: "TWIG Neo SRD MD", price: "0", price_type: "total" },
            { id: "TWIG-One-GPS", name: "TWIG One", price: "4625", price_type: "total" },
            { id: "TWIG-One-SRD", name: "TWIG One SRD", price: "4625", price_type: "total" },
            { id: "TWIG-One-EX", name: "TWIG One EX", price: "4625", price_type: "total" },
            { id: "TWIG-Beacon", name: "TWIG Beacon", price: "1670", price_type: "total" },
            { id: "TWIG-Beacon-EX", name: "TWIG Beacon EX", price: "0", price_type: "total" },
            { id: "EMERIT-WG100", name: "EMERIT WG100", price: "0", price_type: "total" },
            { id: "TELTONIKA-FMC003", name: "TELTONIKA FMC003", price: "0", price_type: "total" },
            { id: "Anchor-Beacon", name: "Anchor Beacon 2", price: "0", price_type: "total" },
            { id: "SecurBas", name: "SecurBas", price: "20", price_type: "month" },
            { id: "SecurFull", name: "SecurFull", price: "109", price_type: "month" },
            { id: "SecurdiaCare", name: "SecurdiaCare", price: "19", price_type: "month" },
            { id: "Simkort", name: "Simkort", price: "20", price_type: "month" },
            { id: "Larmcentral", name: "Larmcentral", price: "69", price_type: "month" },
          ],
        },
        {
          id: "rental",
          active: false,
          prices: [
            { id: "SRT306", name: "SRT306", price: "278", price_type: "month" },
            { id: "SRT306i", name: "SRT306i", price: "278", price_type: "month" },
            { id: "SRT306-G2", name: "SRT306 G2", price: "309", price_type: "month" },
            { id: "SRT326", name: "SRT326", price: "268", price_type: "month" },
            { id: "SRT330", name: "SRT330", price: "379", price_type: "month" },
            { id: "SRT334", name: "SRT334", price: "69", price_type: "month" },
            { id: "SRT405", name: "SRT405", price: "309", price_type: "month" },
            { id: "SRT405i", name: "SRT405i", price: "0", price_type: "month" },
            { id: "SRT406", name: "SRT406", price: "309", price_type: "month" },
            { id: "SRT406i", name: "SRT406i", price: "309", price_type: "month" },
            { id: "SRT430", name: "SRT430", price: "0", price_type: "month" },
            { id: "SRT278", name: "SRT278", price: "0", price_type: "month" },
            { id: "SRT341", name: "SRT341", price: "0", price_type: "month" },
            { id: "TWIG-Neo", name: "TWIG Neo", price: "219", price_type: "month" },
            { id: "TWIG-IPS", name: "TWIG Neo SRD", price: "278", price_type: "month" },
            { id: "TWIG-Neo-Mandown", name: "TWIG Neo MD", price: "219", price_type: "month" },
            { id: "TWIG-Neo-SRD-MD", name: "TWIG Neo SRD MD", price: "0", price_type: "month" },
            { id: "TWIG-One-GPS", name: "TWIG One", price: "278", price_type: "month" },
            { id: "TWIG-One-SRD", name: "TWIG One SRD", price: "278", price_type: "month" },
            { id: "TWIG-One-EX", name: "TWIG One EX", price: "278", price_type: "month" },
            { id: "TWIG-Beacon", name: "TWIG Beacon", price: "69", price_type: "month" },
            { id: "TWIG-Beacon-EX", name: "TWIG Beacon EX", price: "0", price_type: "month" },
            { id: "EMERIT-WG100", name: "EMERIT WG100", price: "0", price_type: "month" },
            { id: "TELTONIKA-FMC003", name: "TELTONIKA FMC003", price: "0", price_type: "month" },
            { id: "Anchor-Beacon", name: "Anchor Beacon 2", price: "0", price_type: "total" },
            { id: "SecurBas", name: "SecurBas", price: "20", price_type: "month" },
            { id: "SecurFull", name: "SecurFull", price: "89", price_type: "month" },
            { id: "SecurdiaCare", name: "SecurdiaCare", price: "19", price_type: "month" },
            { id: "Simkort", name: "Simkort", price: "20", price_type: "month" },
            { id: "Larmcentral", name: "Larmcentral", price: "69", price_type: "month" },
          ],
        },
        {
          id: "kickback",
          active: false,
          prices: [
            { id: "60-month", name: "60 månader", price: "5000", price_type: "total" },
            { id: "36-month", name: "36 månader", price: "3000", price_type: "total" },
          ],
        },
      ],
      specialAccess: [
        { id: "order_sc_qty_default", name: "Beställning - SecurCloud kvantitet aktiverad som standard", active: false },
        { id: "config", name: "Konfigurera larm", active: false },
        { id: "alarm_report", name: "Larmrapport", active: false },
        { id: "create_customer", name: "Skapa kund", active: false },
        { id: "users", name: "Användare", active: false },
        { id: "sc_device_setting", name: "Tilläggstjänster", active: false },
        { id: "securtrack", name: "SecurTrack", active: false },
        { id: "securtool", name: "SecurTool", active: false },
        { id: "test_reminder", name: "Provlarmspåminnelse", active: false },
        { id: "ac_securtrack", name: "Larmcentraler SecurTrack", active: false },
        { id: "ac_securtool", name: "Larmcentraler SecurTool", active: false },
      ],
      whitelabeloptions: [
      { name: this.$t("reseller_edit_modal.yes"), value: true },
      { name: this.$t("reseller_edit_modal.no"), value: false },
      ]
    };
  },

  methods: {
    getAlarmCenters() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarmCenters = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    createReseller() {
      this.$refs.formCreateReseller.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.logo == null) {
          this.handleError(null, this.$t("error_.the_logo_required"));
          return;
        }

        let pricelist = [];

        for (let i = 0; i < this.pricelist.length; i++) {
          let o = this.pricelist[i];

          if (o.active) pricelist.push(o);
        }

        if (pricelist.length == 0) {
          this.handleError(null, this.$t("error_.price_plan"));
          return;
        }

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        var formData = new FormData();

        if (this.reseller_type != "alarm_center") {
          this.alarm_center = "";

          if (this.special_access.indexOf("ac_securtrack") > -1) this.special_access.splice(this.special_access.indexOf("ac_securtrack"), 1);
          if (this.special_access.indexOf("ac_securtool") > -1) this.special_access.splice(this.special_access.indexOf("ac_securtool"), 1);
        }

        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("description", this.description);
        formData.append("company_number", this.company_number);
        formData.append("address", this.address);
        formData.append("zip_code", this.zip_code);
        formData.append("city", this.city);
        formData.append("reference", this.reference);
        formData.append("invoice", this.invoice);
        formData.append("contact_person", this.contact_person);
        formData.append("contact_phone", this.contact_phone);
        formData.append("contact_email", this.contact_email);
        formData.append("reseller_type", this.reseller_type);
        formData.append("alarm_center", this.alarm_center);
        formData.append("pricelist", JSON.stringify(pricelist));
        formData.append("special_access", JSON.stringify(this.special_access));
        formData.append("image", this.logo);
        formData.append("whitelabel", this.whitelabel);
        formData.append("alternativ_order_email", this.alternativ_order_email);
        formData.append("alternativ_error_email", this.alternativ_error_email);

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/reseller`, formData, headers)
          .then(() => {
            // eslint-disable-line
            this.$router.push({ name: "resellers_admin" });
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    fileChangeCreate() {
      if (this.$refs.imageCreate.files.length > 0) this.logo = this.$refs.imageCreate.files[0];
      else this.logo = null;
    },

    setPriceZero(typeId, priceId) {
      let o = _.find(this.pricelist, { id: typeId });
      if (o) {
        let p = _.find(o.prices, { id: priceId });
        if (p) p.price = "0";
      }
    },
  },

  created() {
    this.getAlarmCenters();
    this.setPageTitle("Admin - Ny Återförsäljare", "create_reseller");
  },
};
</script>
